<template>
  <div class="flex flex-nowrap">
    <div class="flex justify-center py-2 flex-1">
      <h1
        class="text-orange px-20 align-middle justify-center text-2xl font-bold p-4"
      >
        Centralised Clinical Activity
      </h1>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
